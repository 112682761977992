* {
  box-sizing: border-box;
}

.loading-txt {
  font-size: 18px;
  font-weight: 600;
  color: #36d7b7;
  /* margin-bottom: 20px; */
}

.landing-sec {
  width: 100%;
  height: 100;
}

.landing-hero-img-box {
  max-width: 1140px;
  margin: 0 auto;
}

.loading-con {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh !important;
}

.loadingcon-inner {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-self: center;
  align-self: center;
  align-items: center;
  justify-content: center;
}

.bg-hero-img-color {
  background-color: #fefefe;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-img {
  width: 100%;
  height: 50vh;
  object-fit: contain;
}

.landing-profile-con {
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  gap: 180px;
  margin-top: 80px;
}

.landing-profile-part {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.landing-profile-img-box {
  width: 200px;
  height: 200px;
  border-radius: 50%;
}

.profile-img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.landing-profile-name {
  font-size: 26px;
  /* color: #b6002e; */
  font-weight: 600;
  margin: 0px;
}

.landing-profile-about-bio {
  font-size: 14px;
  width: 400px;
  text-align: justify;
  margin: 0px;
  max-width: -webkit-fill-available;
}

.landing-profile-job-pos {
  font-size: 22px;
  margin: 0px;
  font-weight: 500;
}

.landing-profile-about-company {
  font-size: 18px;
  margin: 0px;
  font-weight: 500;
}

.landing-profile-post {
  font-size: 16px;
  color: #555;
  margin: 0px;
}

.landing-profile--bni-img-box {
  max-width: 100px;
  max-height: 100px;
}

.landing-profile--bni-logo {
  width: 100%;
  height: 100%;
}

.landing-profile-part2 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-left: -160px;
}

.landing-profile-con-info {
  display: flex;
  gap: 20px;
  align-items: center;
}

.landing-con-icon {
  /* color: #bc002e; */
  font-size: 20px;
  overflow:inherit;
}

.landing-profile-number {
  color: #000;
  font-size: 16px;
  text-decoration: none;
}

.landing-profile-con-last {
  margin: 0;
}

.landing-profile-border {
  background-color: #333;
  width: 1px;
}

.landing-profile-social-section {
  width: 100%;
  /* height: 331px; */
  height: 100%;
  /* background-color: #c8c9c7; */
  background-color: #fefefe;
  margin-top: 80px;
}

.landing-profile-social-inner {
  max-width: 920px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 30px;
  border-top: 1px solid #999;
  border-bottom: 1px solid #999;
}

.landing-profile-social-btns {
  display: flex;
  gap: 30px;
  padding-top: 30px;
}

.landing-profile-social-btn-first {
  width: 350px;
  height: 70px;
  border-radius: 12px;
  border: none;
  /* background-color: #c2052e; */
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  /* cursor: pointer; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing-profile-social-btn {
  width: 80px;
  height: 70px;
  border-radius: 12px;
  border: none;
  /* background-color: #c2052e; */
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  cursor: pointer;
}

.landing-profile-other-btns {
  display: flex;
  gap: 20px;
}

.landing-profile-other-btn {
  width: 50%;
  height: 70px;
  background-color: #fff;
  /* color: #b6002e; */
  font-size: 16px;
  border-radius: 12px;
  /* border: 1px solid #b6002e; */
  cursor: pointer;
}

.landing-profile-other-last-btn {
  width: 100%;
  height: 70px;
  background-color: #fff;
  /* color: #b6002e; */
  font-size: 16px;
  border-radius: 12px;
  /* border: 1px solid #b6002e; */
  cursor: pointer;
  margin-bottom: 30px;
}

/* .landing-profile-post_logo{
    display: flex;
    flex-direction: row;
} */

.landing-profile-social-btn-icon {
  color: #fff;
}

.landing-profile-social-icon-section {
  max-width: 920px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  gap: 25px;
  margin-top: 80px;
  flex-wrap: wrap;
}

.landing-profile-map-sec {
  max-width: 920px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-top: 80px;
}

.youtube-sec {
  margin-top: 60px;
}

.landing-footer {
  margin-top: 80px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.footer-content {
  color: #fff;
  padding-left: 15px;
  padding-right: 15px;
}

.youtube-con-main {
  max-width: 920px;
  margin: 0 auto;
  margin-bottom: 40px;
}

.youtube-con {
  max-width: 920px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  /* justify-content: space-between; */
  border-bottom: 1px solid #999;
  justify-content: center;
}

.youtube-item {
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.youtube-item-icon {
  width: 75px;
  height: 75px;
  cursor: pointer;
}

.last-btn-con {
  max-width: 920px;
  margin: 0 auto;
  display: flex;
  justify-content: end;
  margin-top: 80px;
  /* position: relative; */
}

.toggle-btn-last {
  text-align: center;
  width: 150px;
  height: 50px;
  border-radius: 12px;
  position: fixed;
  bottom: 78px;
  border: none;
}

.toggle-btn-last-link {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  text-decoration: none;
  font-size: 16px;
  color: #fff;
}

.icon-last-btn {
  font-size: 20px;
}

.about-company-con {
  max-width: 920px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 25px;
  /* align-items: center; */
  justify-content: center;
  padding-top: 80px;
}

.all-data-main-head {
  font-size: 26px;
  font-weight: 600;
}

.landing-profile-other-btn-link {
  text-decoration: none;
  border: none !important;
  background: none;
}

.landing-bni-profile_inner-btn {
  /* border: none !important; */
  width: 100%;
  height: 100%;
  background: none;
  border-radius: 12px;
  cursor: pointer;
}

/* Lifhtbox gallery css */

img {
  max-width: 100%;
}

.gallery-wrapper {
  max-width: 960px;
  width: 100%;
  margin: 0 auto;
  padding: 0 1em;
  display: grid;
  /* grid-template-columns: repeat(auto-fill, minmax(230px, 1fr)); */
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1em;
}

.gallery-wrapper .image-wrapper a {
  padding: 0.5em;
  display: block;
  width: 100%;
  text-decoration: none;
  color: #333;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  transition: all 200ms ease-in-out;
}

.gallery-wrapper .image-wrapper a:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}

.gallery-wrapper .image-wrapper a img {
  width: 100%;
}

.gallery-lightboxes .image-lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0ms ease-in-out;
}

.gallery-lightboxes .image-lightbox:target {
  opacity: 1;
  visibility: visible;
}

.gallery-lightboxes .image-lightbox:target .image-lightbox-wrapper {
  opacity: 1;
  transform: scale(1, 1) translateY(0);
}

.gallery-lightboxes .image-lightbox .image-lightbox-wrapper {
  transform: scale(0.95, 0.95) translateY(-30px);
  transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
  opacity: 0;
  margin: 1em auto;
  max-width: 75%;
  padding: 0.5em;
  display: inline-block;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
  position: relative;
}

.gallery-lightboxes .image-lightbox .image-lightbox-wrapper .close {
  width: 1.5em;
  height: 1.5em;
  background: #000;
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  border-radius: 50%;
  box-shadow: 0 0 0 2px white inset, 0 0 5px rgba(0, 0, 0, 0.5);
  position: absolute;
  right: -1em;
  top: -1em;
}

.gallery-lightboxes .image-lightbox .image-lightbox-wrapper .close:before {
  content: "";
  display: block;
  width: 10px;
  height: 2px;
  background: #fff;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -1px 0 0 -5px;
  transform: rotate(-45deg);
}

.gallery-lightboxes .image-lightbox .image-lightbox-wrapper .close:after {
  content: "";
  display: block;
  width: 10px;
  height: 2px;
  background: #fff;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -1px 0 0 -5px;
  transform: rotate(45deg);
}

.gallery-lightboxes .image-lightbox .image-lightbox-wrapper .arrow-left {
  position: absolute;
  top: 0;
  right: 50%;
  bottom: 0;
  left: 0;
}

.gallery-lightboxes .image-lightbox .image-lightbox-wrapper .arrow-left:before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  border-bottom: 0;
  border-right: 0;
  border-radius: 4px 0 0 0;
  position: absolute;
  top: 50%;
  right: 100%;
  cursor: pointer;
  transform: rotate(-45deg) translateY(-50%);
}

.gallery-lightboxes .image-lightbox .image-lightbox-wrapper .arrow-right {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 50%;
}

.gallery-lightboxes .image-lightbox .image-lightbox-wrapper .arrow-right:before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  border-bottom: 0;
  border-left: 0;
  border-radius: 0 4px 0 0;
  position: absolute;
  top: 50%;
  left: 100%;
  cursor: pointer;
  transform: rotate(45deg) translateY(-50%);
}

.gallery-lightboxes .image-lightbox .image-lightbox-wrapper img {
  margin: 0 auto;
  max-height: 70vh;
}

/* light box jalpesh */

.lightbox_main_wrapp {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  margin: 1rem 0px;
  gap: 12px;
}

.image-card {
  width: 24%;
  height: 200px;
  object-fit: cover;
}

#lightbox-img {
  height: 80vh;
  max-width: 90vw;
  object-fit: cover;
}

#lightbox {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.lightbox_btn_wrapp {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
}

.lightbox_btn {
  width: 30px;
  height: 30px;
}

/* Media Query */

@media screen and (max-width: 960px) {
  .landing-profile-social-inner {
    max-width: 720px;
  }

  .landing-profile-social-btn {
    height: 50px;
  }

  .landing-profile-social-btn-first {
    height: 50px;
  }

  .landing-profile-other-btn {
    height: 60px;
  }

  .landing-profile-other-last-btn {
    height: 60px;
  }

  .last-btn-con {
    margin-right: 20px;
  }

  .landing-profile-social-icon-section {
    justify-content: center;
  }

  .hero-img {
    height: 40vh;
  }

  .about-company-con {
    padding-left: 15px;
  }

  .all-data-main-head {
    padding-left: 15px;
  }

  .lightbox_main_wrapp {
    overflow-x: scroll;
    flex-wrap: nowrap;
    width: 98vw;
  }

  .image-card {
    width: 200px;
    height: 200px;
    object-fit: cover;
  }

  #lightbox-img {
    height: 60vh;
    max-width: 80vw;
    object-fit: cover;
  }
}

@media screen and (max-width: 730px) {
  .landing-profile-social-inner {
    max-width: 550px;
  }

  .landing-profile-social-btns {
    /* gap: 0px; */
    justify-content: space-between;
  }

  .landing-profile-con {
    gap: 130px;
  }

  .landing-profile-part2 {
    margin-left: -114px;
  }

  .hero-img {
    height: 35vh;
  }
}

@media screen and (max-width: 600px) {
  .landing-profile-social-inner {
    max-width: 475px;
  }

  .landing-profile-other-btn {
    height: 45px;
  }

  .landing-profile-other-last-btn {
    height: 45px;
  }

  .landing-profile-social-btn-icon {
    font-size: 15px;
  }

  .landing-profile-social-btn {
    height: 38px;
  }

  .hero-img {
    object-fit: fill;
    height: 31vh;
    width: 100%;
  }

  .landing-profile-social-btn-first {
    /* width: 350px; */
    height: 38px;
    border-radius: 12px;
    border: none;
    /* background-color: #c2052e; */
    color: #fff;
    font-size: 14px;
    font-weight: 600;
  }

  .landing-profile-social-btns {
    gap: 15px;
  }

  .landing-profile-con {
    gap: 25px;
  }

  .landing-profile-part2 {
    margin-left: 0px;
  }

  .landing-profile-con {
    margin-top: 45px;
  }

  .about-company-con {
    gap: 15px;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 500px) {
  .landing-profile-con {
    flex-direction: column;
    align-items: center;
  }

  .landing-profile-social-inner {
    max-width: 370px;
  }

  hero-img {
    object-fit: fill;
    height: 25vh;
  }

  .landing-profile-social-btns {
    flex-wrap: wrap;
    justify-content: center;
  }

  .landing-profile-part2 {
    gap: 15px;
    padding-left: 15px;
  }

  .toggle-btn-last {
    text-align: center;
    /* width: 100px; */
    /* height: 40px; */
    border-radius: 12px;
    position: fixed;
    bottom: 78px;
    right: 20px;
  }

  .toggle-btn-last-link {
    text-decoration: none;
    font-size: 14px;
    color: #fff;
  }

  .about-company-con {
    gap: 15px;
  }

  #lightbox-img {
    height: 45vh;
    max-width: 80vw;
    object-fit: cover;
  }

  /* 
    .last-btn-con {
        margin-right: 40px;
    } */
}

@media screen and (max-width: 400px) {
  .btn-readmore {
    padding-left: 0px !important;
  }
}