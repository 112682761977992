.features-main-wrapp {
    width: 100%;
    /* height: 40vh; */
    margin: 2rem 0;
    padding: 1rem 0;
    background: #f7f7ff;
}

.features-base-wrapp {
    font-family: "Montserrat", sans-serif;
    /* width: 80%; */
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 80px;
}

.features-txt {
    color: hsl(233, 13%, 49%);
    font-size: 16px;
}

.features-box {
    display: flex;
    gap: 25px;
    align-items: center;
    width: 300px;
    padding: 10px;
}

.features-flex {
    margin: 0 auto;
    max-width: 1140px;
    display: flex;
    align-items: center;
    /* gap: 25px; */
    flex-wrap: wrap;
    justify-content: space-around;
    row-gap: 25px;
}

.feature-img {
    width: 60px;
    height: 60px;
}

.features-head {
    color: hsl(233, 13%, 49%);
    font-weight: 800;
    font-size: 16px;
}

.features-txt-box {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.features-box:hover {
    background-color: var(--clr-light-blue);
    box-shadow: 0px 16px 32px rgb(0 0 0 / 10%);
    transition: all 1s;
}