* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

nav {
  min-height: 8vh;
  background-color: var(--clr-white);
}

.nav_main_wrapp {
  display: flex;
  justify-content: space-between;
  max-width: 1140px;
  min-height: 8vh;
  margin: 0 auto;
  align-items: center;
  font-family: "Poppins", sans-serif;
}

.logo {
  color: var(--clr-active-blue);
  text-transform: uppercase;
  letter-spacing: 5px;
  font-size: 20px;
}

.nav-links {
  display: flex;
  justify-content: space-between;
  width: 30%;
}

.nav-links li {
  list-style: none;
}

.nav-links a {
  color: var(--clr-active-blue);
  text-decoration: none;
  letter-spacing: 3px;
  font-weight: bold;
  font-size: 14px;
}

.burger {
  display: none;
  cursor: pointer;
  background: transparent;
  border: none;
}

.burger div {
  width: 25px;
  height: 3px;
  background-color: var(--clr-active-blue);
  margin: 5px;
  transition: all 0.3s ease;
}

@media screen and (max-width: 1024px) {
  .nav-links {
    width: 50%;
  }

  .nav_main_wrapp {
    margin: 0 1rem;
  }
}

@media screen and (max-width: 768px) {
  body {
    overflow-x: hidden;
  }

  /* .nav-links {
    position: absolute;
    right: 0px;
    height: 92vh;
    top: 8vh;
    background-color: var(--clr-active-blue);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    transform: translateX(100%);
    transition: transform 0.5s ease-in;
  } */

  .nav-links li {
    opacity: 0;
  }

  .burger {
    /* display: block; */
  }
}

.nav-active {
  transform: translateX(0%);
}

/* 
@keyframes navLinkFade {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
} */

.toggle .line1 {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.toggle .line2 {
  opacity: 0;
}

.toggle .line3 {
  transform: rotate(45deg) translate(-5px, -6px);
}