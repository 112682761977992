.hero_main_wrapp {
  width: 100%;
  min-height: 60vh;
  /* margin: 2rem 0; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero_base_wrapp {
  max-width: 1140px;
  margin: 0 auto;
  /* padding: 2rem; */
  /* width: 200px;
  height: 200px; */
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  width: 100%;
}

/* --------- Hero Text --------- */

.hero_text_wrapp {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 1rem;
  flex: 1.3;
}

.hero_text_wrapp>h1 {
  color: var(--clr-active-blue);
  font-size: 3rem;
  font-weight: 700;

  margin-bottom: 1rem;
}

.hero_text_wrapp>p {
  color: var(--clr-active-blue);
  font-size: 1.05rem;
  line-height: 1.75;
}

.hero_text_wrapp>button {
  border: 0;
  border-radius: 50px;
  padding: 0.75rem 2.75rem;
  background: #4b71ff;
  color: #ffffff;
  box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.25);
  cursor: pointer;
  position: relative;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 1px;
  transition: all 0.3s ease-in-out;
}

.hero_text_wrapp>button:hover {
  transform: translateY(-5px);
  box-shadow: 0 1rem 1.5rem rgba(0, 0, 0, 0.25);
}

/* --------- Hero Card --------- */

.hero_img_wrapp {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.card-hero {
  max-width: 45ch;
  max-height: 45ch;
  width: 80%;
  height: 80%;
  background: grey;
  border-radius: 5px;
  background-image: url(https://drscdn.500px.org/photo/435236/q%3D80_m%3D1500/v2?webp=true&sig=67031bdff6f582f3e027311e2074be452203ab637c0bd21d89128844becf8e40);
  background-size: cover;
  background-position: center center;
  box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.5s;
  will-change: transform;
  border: 15px solid white;
}

.card-hero:hover {
  box-shadow: 0px 30px 100px -10px rgba(0, 0, 0, 0.4);
}



/* Media Query */

@media screen and (max-width: 1140px) {
  .hero_base_wrapp {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 950px) {
  .card-hero {
    width: 500px;
    height: 350px;
  }

  .banner .banner-text {
    padding-left: 15px;
  }

  .hero_base_wrapp {
    flex-direction: column;
    margin-top: 80px;
  }
}

@media screen and (max-width: 515px) {
  .card-hero {
    width: 100%;
    height: 350px;
  }
}