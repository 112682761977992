.banner {
  height: auto;
  margin: 32px auto;
  max-width: 1140px;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 1;
}

.banner .banner-image {
  position: absolute;
  opacity: 0.25;
  right: 0px;
  top: 0px;
  height: 300px;
  z-index: -1;
  filter: drop-shadow(0 3rem 0.05rem #bfd8ff);
}

.banner .banner-text {
  max-width: 500px;
  float: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}
.banner .banner-text h1 {
  color: #00106a;
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: 3px;
  margin-bottom: 1rem;
}
.banner .banner-text p {
  color: #00106a;
  font-size: 1.05rem;
  line-height: 1.75;
}
.banner button {
  border: 0;
  border-radius: 50px;
  padding: 0.75rem 2.75rem;
  background: #4b71ff;
  color: #ffffff;
  box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.25);
  cursor: pointer;
  position: relative;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 1px;
  transition: all 0.3s ease-in-out;
}
.banner button:hover {
  transform: translateY(-5px);
  box-shadow: 0 1rem 1.5rem rgba(0, 0, 0, 0.25);
}

@media (min-width: 1024px) {
  .banner-text {
    margin-left: 12px;
  }
}

@media (min-width: 992px) {
  .banner .banner-image {
    height: 500px;
    top: -50px;
    opacity: 1;
  }
}

@media (min-width: 768px) {
  .banner .banner-image {
    display: inherit;
    height: 400px;
    top: 0px;
    opacity: 0.5;
  }
  .banner {
    height: 450px;
    margin-bottom: 32px;
  }
}
