@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,300;1,400&display=swap");

:root {
  /* --font-base: "Manrope";
  --font-alt: "Inter", sans-serif; */
  --clr-white: #ffffff;
  --clr-light-blue: #dfe9ff;
  --clr-active-blue: #4b71ff;
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
