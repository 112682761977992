.mb-30 {
  margin-bottom: 30px;
}

.row {
  display: flex;
  align-items: center;

  /* gap: 1%; */
  flex-wrap: wrap;

  /* max-width: 1140px;
  margin: 0 auto; */
  /* gap: 1%; */
  gap: 15px;
}

.card {
  padding: 16px 24px;
  background: #ffffff;
  height: 100%;
  position: relative;
  border: none;
  box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.15);
  border: 2px solid transparent;
  transition: all 0.3s ease-in-out;
  z-index: 10;
  /* width: 32.65%;
  height: 300px; */
  /* width: 23.65%; */
  width: 265px;
  height: 375px;
  margin-bottom: 1%;
}

.card h3.card-title {
  font-weight: 700;
  font-size: 1.3rem;
  color: #00106a;
}

.card p {
  color: #989dc5;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 72px;
  margin-top: 10px;
}

.card .card-link {
  position: absolute;
  bottom: 18px;
  text-decoration: none;
}

.card:hover {
  transform: translateY(-15px);
  box-shadow: 0 1rem 1.5rem rgba(0, 0, 0, 0.15);
  cursor: pointer;
}

.card .card-icon {
  width: 60px;
  margin-bottom: 8px;
  position: relative;
  top: 0;
  left: 0px;
}

.cards-main {
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  gap: 25px;
}

.cards-img-part {
  max-width: 300px;
  height: 100%;
}

.goal-img {
  width: 100%;
  height: 100%;
}


/* Media Query */

@media screen and (max-width:1130px) {
  .row {
    justify-content: center;
  }
}