.pricing_main_wrapp {
  width: 100%;
  /* height: 40vh; */
  margin: 2rem 0;
  padding: 1rem 0;
  background: #f7f7ff;
}

.pricing_base_wrapp {
  font-family: "Montserrat", sans-serif;
  width: 80%;
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 80px;
  /* overflow-x: hidden; */
}

header {
  color: hsl(233, 13%, 49%);
  margin: 3.3rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.toggle {
  margin-top: 2rem;
  color: hsl(234, 14%, 74%);
  display: flex;
  align-items: center;
}

.toggle-btn {
  margin: 0 1rem;
}

.checkbox {
  display: none;
}

.sub {
  background: linear-gradient(135deg,
      rgba(163, 168, 240, 1) 0%,
      rgba(105, 111, 221, 1) 100%);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* height: 25px;
  width: 50px; */
  height: 1.6rem;
  width: 3.3rem;
  border-radius: 1.6rem;
  padding: 0.3rem;
}

.circle {
  background-color: #fff;
  height: 1.4rem;
  width: 1.4rem;
  border-radius: 50%;
}

.checkbox:checked+.sub {
  justify-content: flex-end;
}

.cards_price {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.card_price {
  background: #fff;
  color: hsl(233, 13%, 49%);
  border-radius: 0.8rem;
}

.cards_price .card_price.active {
  background: linear-gradient(135deg,
      rgba(163, 168, 240, 1) 0%,
      rgba(105, 111, 221, 1) 100%);
  color: #fff;
  display: flex;
  align-items: center;
  transform: scale(1.1);
  z-index: 1;
}

ul {
  margin: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

ul li {
  list-style-type: none;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0.7rem 0;
}

ul li.price {
  font-size: 2rem;
  color: hsl(232, 13%, 33%);
  padding-bottom: 2rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

.shadow {
  box-shadow: -5px 5px 15px 1px rgba(0, 0, 0, 0.1);
}

.card_price.active .price {
  color: #fff;
}

.btn_price {
  margin-top: 1rem;
  height: 2.6rem;
  width: 13.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: linear-gradient(135deg,
      rgba(163, 168, 240, 1) 0%,
      rgba(105, 111, 221, 1) 100%);
  color: #fff;
  outline: none;
  border: 0;
  font-weight: bold;
}

.active-btn_price {
  background: #fff;
  color: hsl(237, 63%, 64%);
}

.bottom-bar {
  border-bottom: 2px solid hsla(240, 8%, 85%, 0.582);
}

.card_price.active .bottom-bar {
  border-bottom: 2px solid hsla(240, 8%, 85%, 0.253);
}

.pack {
  font-size: 1.1rem;
}

@media (max-width: 280px) {
  ul {
    margin: 1rem;
  }

  h1 {
    font-size: 1.2rem;
  }

  .toggle {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 80px;
  }

  .cards_price {
    margin: 0;
    display: flex;
    flex-direction: column;
  }

  .card_price {
    transform: scale(0.8);
    margin-bottom: 1rem;
  }

  .cards_price .card_price.active {
    transform: scale(0.8);
  }
}

@media (min-width: 280px) and (max-width: 320px) {
  ul {
    margin: 20px;
  }

  .cards_price {
    display: flex;
    flex-direction: column;
  }

  .card_price {
    margin-bottom: 1rem;
  }

  .cards_price .card_price.active {
    transform: scale(1);
  }
}

@media (min-width: 320px) and (max-width: 414px) {
  .cards_price {
    display: flex;
    flex-direction: column;
  }

  .card_price {
    margin-bottom: 1rem;
  }

  .cards_price .card_price.active {
    transform: scale(1);
  }
}

@media (min-width: 414px) and (max-width: 768px) {
  .card_price {
    margin-bottom: 1rem;
    margin-right: 1rem;
  }

  .cards_price .card_price.active {
    transform: scale(1);
  }
}

@media (min-width: 768px) and (max-width: 1046px) {
  .cards_price {
    display: flex;
  }

  .card_price {
    margin-bottom: 1rem;
    margin-right: 1rem;
  }

  .cards_price .card_price.active {
    transform: scale(1);
  }
}